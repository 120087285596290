import { LoginForm, AuthContext } from "@rachio-npm/rachio-retail-common";
import { Link } from "gatsby";
import React, { useContext, useState } from "react";
import { Container, Card, Alert } from "../../styles";

export const ProLoginForm = () => {
  const { login } = useContext(AuthContext);
  const [error, setError] = useState("");

  const handleLoginFailure = () => {
    setError("You have entered an incorrect password. Please try again.");
  }

  const onSubmit = async (password: string) => {
    await login(password, handleLoginFailure);
  }

  return (
    <Container css="margin: 0 var(--basePadding); max-width: 500px; margin: 0 auto; text-align: center;">
      <Card>
        <Alert>
          New store and updated products coming soon!"
        </Alert>
        <h4 css="margin-top: 0">Enter your Rachio Pro store password</h4>
        <p>Password provided by your Rachio representative.</p>
        <LoginForm
          data-test-id="login-form"
          onSubmit={onSubmit}
          error={error}
          buttonText="Access Store"
        />
        <p>
          Not a Rachio Pro? <Link to="/signup/">Sign up today</Link>.
        </p>
      </Card>
    </Container>
  )
}